/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES (INITIALIZATION) ----------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- IMPORTS ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

export const tone_card = [];
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#7f5744" );
			 tone_card.push( "#67493a" );
			 tone_card.push( "#6e4d3d" );
			 tone_card.push( "#c18f7f" );
			 tone_card.push( "#56423c" );
			 tone_card.push( "#a2706c" );
			 tone_card.push( "#b7957d" );
			 tone_card.push( "#664b41" );
			 tone_card.push( "#8a624d" );
			 tone_card.push( "#584844" );
			 tone_card.push( "#a78067" );
			 tone_card.push( "#a37865" );
			 tone_card.push( "#b29381" );
			 tone_card.push( "#7d7d7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#a88066" );
			 tone_card.push( "#56433e" );
			 tone_card.push( "#886652" );
			 tone_card.push( "#a48062" );
			 tone_card.push( "#694e40" );
			 tone_card.push( "#bba189" );
			 tone_card.push( "#bb9f86" );
			 tone_card.push( "#745445" );
			 tone_card.push( "#7b5647" );
			 tone_card.push( "#553f39" );
			 tone_card.push( "#94725c" );
			 tone_card.push( "#a07f65" );
			 tone_card.push( "#987367" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#735145" );
			 tone_card.push( "#9d7964" );
			 tone_card.push( "#8b6149" );
			 tone_card.push( "#84624d" );
			 tone_card.push( "#af8b7b" );
			 tone_card.push( "#b39483" );
			 tone_card.push( "#ba9e83" );
			 tone_card.push( "#52403b" );
			 tone_card.push( "#a17963" );
			 tone_card.push( "#8d5e4f" );
			 tone_card.push( "#9f7d6a" );
			 tone_card.push( "#b68f79" );
			 tone_card.push( "#6e5645" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#56453f" );
			 tone_card.push( "#6c4b3d" );
			 tone_card.push( "#4a362f" );
			 tone_card.push( "#bd9582" );
			 tone_card.push( "#bc8c83" );
			 tone_card.push( "#916b52" );
			 tone_card.push( "#ae8671" );
			 tone_card.push( "#7f5b47" );
			 tone_card.push( "#996c5a" );
			 tone_card.push( "#413632" );
			 tone_card.push( "#ab9178" );
			 tone_card.push( "#b2937d" );
			 tone_card.push( "#8f6959" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#a0705a" );
			 tone_card.push( "#51413e" );
			 tone_card.push( "#6c4b3d" );
			 tone_card.push( "#6a5446" );
			 tone_card.push( "#9b7962" );
			 tone_card.push( "#60473f" );
			 tone_card.push( "#b98e7c" );
			 tone_card.push( "#58443d" );
			 tone_card.push( "#8a664d" );
			 tone_card.push( "#2d1e17" );
			 tone_card.push( "#a47f6d" );
			 tone_card.push( "#9b765d" );
			 tone_card.push( "#af867b" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#8f6855" );
			 tone_card.push( "#856048" );
			 tone_card.push( "#775449" );
			 tone_card.push( "#bc9e8b" );
			 tone_card.push( "#6d4e43" );
			 tone_card.push( "#a8886c" );
			 tone_card.push( "#b3836e" );
			 tone_card.push( "#61473d" );
			 tone_card.push( "#433937" );
			 tone_card.push( "#b69179" );
			 tone_card.push( "#a58a72" );
			 tone_card.push( "#b38f7e" );
			 tone_card.push( "#82665a" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#99755f" );
			 tone_card.push( "#976a56" );
			 tone_card.push( "#4f3c38" );
			 tone_card.push( "#99765b" );
			 tone_card.push( "#191819" );
			 tone_card.push( "#4b4a4b" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#afaeaf" );
			 tone_card.push( "#e1e0e1" );
			 tone_card.push( "#af8068" );
			 tone_card.push( "#987260" );
			 tone_card.push( "#a98a78" );
			 tone_card.push( "#775746" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#7c5346" );
			 tone_card.push( "#56433c" );
			 tone_card.push( "#a9846b" );
			 tone_card.push( "#927160" );
			 tone_card.push( "#323132" );
			 tone_card.push( "#646364" );
			 tone_card.push( "#969596" );
			 tone_card.push( "#c8c7c8" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#b29176" );
			 tone_card.push( "#a8856d" );
			 tone_card.push( "#876750" );
			 tone_card.push( "#6e5244" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#6c4f42" );
			 tone_card.push( "#a8876d" );
			 tone_card.push( "#b38674" );
			 tone_card.push( "#b49d87" );
			 tone_card.push( "#ae8376" );
			 tone_card.push( "#9b725a" );
			 tone_card.push( "#a97e6c" );
			 tone_card.push( "#6f4f43" );
			 tone_card.push( "#42322e" );
			 tone_card.push( "#b28776" );
			 tone_card.push( "#a0836d" );
			 tone_card.push( "#af8d71" );
			 tone_card.push( "#9c6e62" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#7b5648" );
			 tone_card.push( "#875d4e" );
			 tone_card.push( "#a57568" );
			 tone_card.push( "#ae9277" );
			 tone_card.push( "#795745" );
			 tone_card.push( "#ae8570" );
			 tone_card.push( "#b58777" );
			 tone_card.push( "#956f56" );
			 tone_card.push( "#87594b" );
			 tone_card.push( "#90674a" );
			 tone_card.push( "#a78574" );
			 tone_card.push( "#9f8065" );
			 tone_card.push( "#715e51" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#97755c" );
			 tone_card.push( "#8e624d" );
			 tone_card.push( "#b29075" );
			 tone_card.push( "#8a6e57" );
			 tone_card.push( "#956b59" );
			 tone_card.push( "#a58370" );
			 tone_card.push( "#c38c7e" );
			 tone_card.push( "#7f5848" );
			 tone_card.push( "#895d46" );
			 tone_card.push( "#a87959" );
			 tone_card.push( "#a68975" );
			 tone_card.push( "#876451" );
			 tone_card.push( "#bb9d8a" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#4c3f3b" );
			 tone_card.push( "#835b4d" );
			 tone_card.push( "#a47666" );
			 tone_card.push( "#a47e6e" );
			 tone_card.push( "#ad8e79" );
			 tone_card.push( "#b29072" );
			 tone_card.push( "#60473e" );
			 tone_card.push( "#8d654d" );
			 tone_card.push( "#956454" );
			 tone_card.push( "#816148" );
			 tone_card.push( "#b39c85" );
			 tone_card.push( "#947961" );
			 tone_card.push( "#5c4336" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#a37c62" );
			 tone_card.push( "#8e6651" );
			 tone_card.push( "#b48d77" );
			 tone_card.push( "#8c665a" );
			 tone_card.push( "#a47965" );
			 tone_card.push( "#aa856a" );
			 tone_card.push( "#674b40" );
			 tone_card.push( "#765543" );
			 tone_card.push( "#6d4c43" );
			 tone_card.push( "#b18d6f" );
			 tone_card.push( "#b18671" );
			 tone_card.push( "#a87c6c" );
			 tone_card.push( "#9d716a" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#58433d" );
			 tone_card.push( "#a1735f" );
			 tone_card.push( "#bc8378" );
			 tone_card.push( "#89624d" );
			 tone_card.push( "#bb9384" );
			 tone_card.push( "#b18b74" );
			 tone_card.push( "#6c4d40" );
			 tone_card.push( "#906a55" );
			 tone_card.push( "#5a534d" );
			 tone_card.push( "#a78263" );
			 tone_card.push( "#987a66" );
			 tone_card.push( "#97775d" );
			 tone_card.push( "#6c4a43" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d5a48" );
			 tone_card.push( "#a37c62" );
			 tone_card.push( "#a3856e" );
			 tone_card.push( "#9f775c" );
			 tone_card.push( "#a77e67" );
			 tone_card.push( "#bb947f" );
			 tone_card.push( "#5c453f" );
			 tone_card.push( "#a78268" );
			 tone_card.push( "#885d45" );
			 tone_card.push( "#a57467" );
			 tone_card.push( "#ae8574" );
			 tone_card.push( "#866d58" );
			 tone_card.push( "#34201c" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#986b59" );
			 tone_card.push( "#815b48" );
			 tone_card.push( "#795647" );
			 tone_card.push( "#a4766d" );
			 tone_card.push( "#ad927d" );
			 tone_card.push( "#a87f67" );
			 tone_card.push( "#755242" );
			 tone_card.push( "#845f4a" );
			 tone_card.push( "#8c6649" );
			 tone_card.push( "#9e6b5b" );
			 tone_card.push( "#ae947f" );
			 tone_card.push( "#836a5a" );
			 tone_card.push( "#4d352d" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );
			 tone_card.push( "#fffeff" );
			 tone_card.push( "#7d7c7d" );
			 tone_card.push( "#000000" );

export const countries = [];
			 countries.push( { country: "Afghanistan", alpha2: "AF", alpha3: "AFG", numeric: "4" } );
			 countries.push( { country: "Albania", alpha2: "AL", alpha3: "ALB", numeric: "8" } );
			 countries.push( { country: "Algeria", alpha2: "DZ", alpha3: "DZA", numeric: "12" } );
			 countries.push( { country: "American Samoa", alpha2: "AS", alpha3: "ASM", numeric: "16" } );
			 countries.push( { country: "Andorra", alpha2: "AD", alpha3: "AND", numeric: "20" } );
			 countries.push( { country: "Angola", alpha2: "AO", alpha3: "AGO", numeric: "24" } );
			 countries.push( { country: "Anguilla", alpha2: "AI", alpha3: "AIA", numeric: "660" } );
			 countries.push( { country: "Antarctica", alpha2: "AQ", alpha3: "ATA", numeric: "10" } );
			 countries.push( { country: "Antigua and Barbuda", alpha2: "AG", alpha3: "ATG", numeric: "28" } );
			 countries.push( { country: "Argentina", alpha2: "AR", alpha3: "ARG", numeric: "32" } );
			 countries.push( { country: "Armenia", alpha2: "AM", alpha3: "ARM", numeric: "51" } );
			 countries.push( { country: "Aruba", alpha2: "AW", alpha3: "ABW", numeric: "533" } );
			 countries.push( { country: "Australia", alpha2: "AU", alpha3: "AUS", numeric: "36" } );
			 countries.push( { country: "Austria", alpha2: "AT", alpha3: "AUT", numeric: "40" } );
			 countries.push( { country: "Azerbaijan", alpha2: "AZ", alpha3: "AZE", numeric: "31" } );
			 countries.push( { country: "Bahamas (the)", alpha2: "BS", alpha3: "BHS", numeric: "44" } );
			 countries.push( { country: "Bahrain", alpha2: "BH", alpha3: "BHR", numeric: "48" } );
			 countries.push( { country: "Bangladesh", alpha2: "BD", alpha3: "BGD", numeric: "50" } );
			 countries.push( { country: "Barbados", alpha2: "BB", alpha3: "BRB", numeric: "52" } );
			 countries.push( { country: "Belarus", alpha2: "BY", alpha3: "BLR", numeric: "112" } );
			 countries.push( { country: "Belgium", alpha2: "BE", alpha3: "BEL", numeric: "56" } );
			 countries.push( { country: "Belize", alpha2: "BZ", alpha3: "BLZ", numeric: "84" } );
			 countries.push( { country: "Benin", alpha2: "BJ", alpha3: "BEN", numeric: "204" } );
			 countries.push( { country: "Bermuda", alpha2: "BM", alpha3: "BMU", numeric: "60" } );
			 countries.push( { country: "Bhutan", alpha2: "BT", alpha3: "BTN", numeric: "64" } );
			 countries.push( { country: "Bolivia (Plurinational State of)", alpha2: "BO", alpha3: "BOL", numeric: "68" } );
			 countries.push( { country: "Bonaire, Sint Eustatius and Saba", alpha2: "BQ", alpha3: "BES", numeric: "535" } );
			 countries.push( { country: "Bosnia and Herzegovina", alpha2: "BA", alpha3: "BIH", numeric: "70" } );
			 countries.push( { country: "Botswana", alpha2: "BW", alpha3: "BWA", numeric: "72" } );
			 countries.push( { country: "Bouvet Island", alpha2: "BV", alpha3: "BVT", numeric: "74" } );
			 countries.push( { country: "Brazil", alpha2: "BR", alpha3: "BRA", numeric: "76" } );
			 countries.push( { country: "British Indian Ocean Territory (the)", alpha2: "IO", alpha3: "IOT", numeric: "86" } );
			 countries.push( { country: "Brunei Darussalam", alpha2: "BN", alpha3: "BRN", numeric: "96" } );
			 countries.push( { country: "Bulgaria", alpha2: "BG", alpha3: "BGR", numeric: "100" } );
			 countries.push( { country: "Burkina Faso", alpha2: "BF", alpha3: "BFA", numeric: "854" } );
			 countries.push( { country: "Burundi", alpha2: "BI", alpha3: "BDI", numeric: "108" } );
			 countries.push( { country: "Cabo Verde", alpha2: "CV", alpha3: "CPV", numeric: "132" } );
			 countries.push( { country: "Cambodia", alpha2: "KH", alpha3: "KHM", numeric: "116" } );
			 countries.push( { country: "Cameroon", alpha2: "CM", alpha3: "CMR", numeric: "120" } );
			 countries.push( { country: "Canada", alpha2: "CA", alpha3: "CAN", numeric: "124" } );
			 countries.push( { country: "Cayman Islands (the)", alpha2: "KY", alpha3: "CYM", numeric: "136" } );
			 countries.push( { country: "Central African Republic (the)", alpha2: "CF", alpha3: "CAF", numeric: "140" } );
			 countries.push( { country: "Chad", alpha2: "TD", alpha3: "TCD", numeric: "148" } );
			 countries.push( { country: "Chile", alpha2: "CL", alpha3: "CHL", numeric: "152" } );
			 countries.push( { country: "China", alpha2: "CN", alpha3: "CHN", numeric: "156" } );
			 countries.push( { country: "Christmas Island", alpha2: "CX", alpha3: "CXR", numeric: "162" } );
			 countries.push( { country: "Cocos (Keeling) Islands (the)", alpha2: "CC", alpha3: "CCK", numeric: "166" } );
			 countries.push( { country: "Colombia", alpha2: "CO", alpha3: "COL", numeric: "170" } );
			 countries.push( { country: "Comoros (the)", alpha2: "KM", alpha3: "COM", numeric: "174" } );
			 countries.push( { country: "Congo (the Democratic Republic of the)", alpha2: "CD", alpha3: "COD", numeric: "180" } );
			 countries.push( { country: "Congo (the)", alpha2: "CG", alpha3: "COG", numeric: "178" } );
			 countries.push( { country: "Cook Islands (the)", alpha2: "CK", alpha3: "COK", numeric: "184" } );
			 countries.push( { country: "Costa Rica", alpha2: "CR", alpha3: "CRI", numeric: "188" } );
			 countries.push( { country: "Croatia", alpha2: "HR", alpha3: "HRV", numeric: "191" } );
			 countries.push( { country: "Cuba", alpha2: "CU", alpha3: "CUB", numeric: "192" } );
			 countries.push( { country: "Curaçao", alpha2: "CW", alpha3: "CUW", numeric: "531" } );
			 countries.push( { country: "Cyprus", alpha2: "CY", alpha3: "CYP", numeric: "196" } );
			 countries.push( { country: "Czechia", alpha2: "CZ", alpha3: "CZE", numeric: "203" } );
			 countries.push( { country: "Côte d'Ivoire", alpha2: "CI", alpha3: "CIV", numeric: "384" } );
			 countries.push( { country: "Denmark", alpha2: "DK", alpha3: "DNK", numeric: "208" } );
			 countries.push( { country: "Djibouti", alpha2: "DJ", alpha3: "DJI", numeric: "262" } );
			 countries.push( { country: "Dominica", alpha2: "DM", alpha3: "DMA", numeric: "212" } );
			 countries.push( { country: "Dominican Republic (the)", alpha2: "DO", alpha3: "DOM", numeric: "214" } );
			 countries.push( { country: "Ecuador", alpha2: "EC", alpha3: "ECU", numeric: "218" } );
			 countries.push( { country: "Egypt", alpha2: "EG", alpha3: "EGY", numeric: "818" } );
			 countries.push( { country: "El Salvador", alpha2: "SV", alpha3: "SLV", numeric: "222" } );
			 countries.push( { country: "Equatorial Guinea", alpha2: "GQ", alpha3: "GNQ", numeric: "226" } );
			 countries.push( { country: "Eritrea", alpha2: "ER", alpha3: "ERI", numeric: "232" } );
			 countries.push( { country: "Estonia", alpha2: "EE", alpha3: "EST", numeric: "233" } );
			 countries.push( { country: "Eswatini", alpha2: "SZ", alpha3: "SWZ", numeric: "748" } );
			 countries.push( { country: "Ethiopia", alpha2: "ET", alpha3: "ETH", numeric: "231" } );
			 countries.push( { country: "Falkland Islands (the) [Malvinas]", alpha2: "FK", alpha3: "FLK", numeric: "238" } );
			 countries.push( { country: "Faroe Islands (the)", alpha2: "FO", alpha3: "FRO", numeric: "234" } );
			 countries.push( { country: "Fiji", alpha2: "FJ", alpha3: "FJI", numeric: "242" } );
			 countries.push( { country: "Finland", alpha2: "FI", alpha3: "FIN", numeric: "246" } );
			 countries.push( { country: "France", alpha2: "FR", alpha3: "FRA", numeric: "250" } );
			 countries.push( { country: "French Guiana", alpha2: "GF", alpha3: "GUF", numeric: "254" } );
			 countries.push( { country: "French Polynesia", alpha2: "PF", alpha3: "PYF", numeric: "258" } );
			 countries.push( { country: "French Southern Territories (the)", alpha2: "TF", alpha3: "ATF", numeric: "260" } );
			 countries.push( { country: "Gabon", alpha2: "GA", alpha3: "GAB", numeric: "266" } );
			 countries.push( { country: "Gambia (the)", alpha2: "GM", alpha3: "GMB", numeric: "270" } );
			 countries.push( { country: "Georgia", alpha2: "GE", alpha3: "GEO", numeric: "268" } );
			 countries.push( { country: "Germany", alpha2: "DE", alpha3: "DEU", numeric: "276" } );
			 countries.push( { country: "Ghana", alpha2: "GH", alpha3: "GHA", numeric: "288" } );
			 countries.push( { country: "Gibraltar", alpha2: "GI", alpha3: "GIB", numeric: "292" } );
			 countries.push( { country: "Greece", alpha2: "GR", alpha3: "GRC", numeric: "300" } );
			 countries.push( { country: "Greenland", alpha2: "GL", alpha3: "GRL", numeric: "304" } );
			 countries.push( { country: "Grenada", alpha2: "GD", alpha3: "GRD", numeric: "308" } );
			 countries.push( { country: "Guadeloupe", alpha2: "GP", alpha3: "GLP", numeric: "312" } );
			 countries.push( { country: "Guam", alpha2: "GU", alpha3: "GUM", numeric: "316" } );
			 countries.push( { country: "Guatemala", alpha2: "GT", alpha3: "GTM", numeric: "320" } );
			 countries.push( { country: "Guernsey", alpha2: "GG", alpha3: "GGY", numeric: "831" } );
			 countries.push( { country: "Guinea", alpha2: "GN", alpha3: "GIN", numeric: "324" } );
			 countries.push( { country: "Guinea-Bissau", alpha2: "GW", alpha3: "GNB", numeric: "624" } );
			 countries.push( { country: "Guyana", alpha2: "GY", alpha3: "GUY", numeric: "328" } );
			 countries.push( { country: "Haiti", alpha2: "HT", alpha3: "HTI", numeric: "332" } );
			 countries.push( { country: "Heard Island and McDonald Islands", alpha2: "HM", alpha3: "HMD", numeric: "334" } );
			 countries.push( { country: "Holy See (the)", alpha2: "VA", alpha3: "VAT", numeric: "336" } );
			 countries.push( { country: "Honduras", alpha2: "HN", alpha3: "HND", numeric: "340" } );
			 countries.push( { country: "Hong Kong", alpha2: "HK", alpha3: "HKG", numeric: "344" } );
			 countries.push( { country: "Hungary", alpha2: "HU", alpha3: "HUN", numeric: "348" } );
			 countries.push( { country: "Iceland", alpha2: "IS", alpha3: "ISL", numeric: "352" } );
			 countries.push( { country: "India", alpha2: "IN", alpha3: "IND", numeric: "356" } );
			 countries.push( { country: "Indonesia", alpha2: "ID", alpha3: "IDN", numeric: "360" } );
			 countries.push( { country: "Iran (Islamic Republic of)", alpha2: "IR", alpha3: "IRN", numeric: "364" } );
			 countries.push( { country: "Iraq", alpha2: "IQ", alpha3: "IRQ", numeric: "368" } );
			 countries.push( { country: "Ireland", alpha2: "IE", alpha3: "IRL", numeric: "372" } );
			 countries.push( { country: "Isle of Man", alpha2: "IM", alpha3: "IMN", numeric: "833" } );
			 countries.push( { country: "Israel", alpha2: "IL", alpha3: "ISR", numeric: "376" } );
			 countries.push( { country: "Italy", alpha2: "IT", alpha3: "ITA", numeric: "380" } );
			 countries.push( { country: "Jamaica", alpha2: "JM", alpha3: "JAM", numeric: "388" } );
			 countries.push( { country: "Japan", alpha2: "JP", alpha3: "JPN", numeric: "392" } );
			 countries.push( { country: "Jersey", alpha2: "JE", alpha3: "JEY", numeric: "832" } );
			 countries.push( { country: "Jordan", alpha2: "JO", alpha3: "JOR", numeric: "400" } );
			 countries.push( { country: "Kazakhstan", alpha2: "KZ", alpha3: "KAZ", numeric: "398" } );
			 countries.push( { country: "Kenya", alpha2: "KE", alpha3: "KEN", numeric: "404" } );
			 countries.push( { country: "Kiribati", alpha2: "KI", alpha3: "KIR", numeric: "296" } );
			 countries.push( { country: "Korea (the Democratic People's Republic of)", alpha2: "KP", alpha3: "PRK", numeric: "408" } );
			 countries.push( { country: "Korea (the Republic of)", alpha2: "KR", alpha3: "KOR", numeric: "410" } );
			 countries.push( { country: "Kuwait", alpha2: "KW", alpha3: "KWT", numeric: "414" } );
			 countries.push( { country: "Kyrgyzstan", alpha2: "KG", alpha3: "KGZ", numeric: "417" } );
			 countries.push( { country: "Lao People's Democratic Republic (the)", alpha2: "LA", alpha3: "LAO", numeric: "418" } );
			 countries.push( { country: "Latvia", alpha2: "LV", alpha3: "LVA", numeric: "428" } );
			 countries.push( { country: "Lebanon", alpha2: "LB", alpha3: "LBN", numeric: "422" } );
			 countries.push( { country: "Lesotho", alpha2: "LS", alpha3: "LSO", numeric: "426" } );
			 countries.push( { country: "Liberia", alpha2: "LR", alpha3: "LBR", numeric: "430" } );
			 countries.push( { country: "Libya", alpha2: "LY", alpha3: "LBY", numeric: "434" } );
			 countries.push( { country: "Liechtenstein", alpha2: "LI", alpha3: "LIE", numeric: "438" } );
			 countries.push( { country: "Lithuania", alpha2: "LT", alpha3: "LTU", numeric: "440" } );
			 countries.push( { country: "Luxembourg", alpha2: "LU", alpha3: "LUX", numeric: "442" } );
			 countries.push( { country: "Macao", alpha2: "MO", alpha3: "MAC", numeric: "446" } );
			 countries.push( { country: "Madagascar", alpha2: "MG", alpha3: "MDG", numeric: "450" } );
			 countries.push( { country: "Malawi", alpha2: "MW", alpha3: "MWI", numeric: "454" } );
			 countries.push( { country: "Malaysia", alpha2: "MY", alpha3: "MYS", numeric: "458" } );
			 countries.push( { country: "Maldives", alpha2: "MV", alpha3: "MDV", numeric: "462" } );
			 countries.push( { country: "Mali", alpha2: "ML", alpha3: "MLI", numeric: "466" } );
			 countries.push( { country: "Malta", alpha2: "MT", alpha3: "MLT", numeric: "470" } );
			 countries.push( { country: "Marshall Islands (the)", alpha2: "MH", alpha3: "MHL", numeric: "584" } );
			 countries.push( { country: "Martinique", alpha2: "MQ", alpha3: "MTQ", numeric: "474" } );
			 countries.push( { country: "Mauritania", alpha2: "MR", alpha3: "MRT", numeric: "478" } );
			 countries.push( { country: "Mauritius", alpha2: "MU", alpha3: "MUS", numeric: "480" } );
			 countries.push( { country: "Mayotte", alpha2: "YT", alpha3: "MYT", numeric: "175" } );
			 countries.push( { country: "Mexico", alpha2: "MX", alpha3: "MEX", numeric: "484" } );
			 countries.push( { country: "Micronesia (Federated States of)", alpha2: "FM", alpha3: "FSM", numeric: "583" } );
			 countries.push( { country: "Moldova (the Republic of)", alpha2: "MD", alpha3: "MDA", numeric: "498" } );
			 countries.push( { country: "Monaco", alpha2: "MC", alpha3: "MCO", numeric: "492" } );
			 countries.push( { country: "Mongolia", alpha2: "MN", alpha3: "MNG", numeric: "496" } );
			 countries.push( { country: "Montenegro", alpha2: "ME", alpha3: "MNE", numeric: "499" } );
			 countries.push( { country: "Montserrat", alpha2: "MS", alpha3: "MSR", numeric: "500" } );
			 countries.push( { country: "Morocco", alpha2: "MA", alpha3: "MAR", numeric: "504" } );
			 countries.push( { country: "Mozambique", alpha2: "MZ", alpha3: "MOZ", numeric: "508" } );
			 countries.push( { country: "Myanmar", alpha2: "MM", alpha3: "MMR", numeric: "104" } );
			 countries.push( { country: "Namibia", alpha2: "NA", alpha3: "NAM", numeric: "516" } );
			 countries.push( { country: "Nauru", alpha2: "NR", alpha3: "NRU", numeric: "520" } );
			 countries.push( { country: "Nepal", alpha2: "NP", alpha3: "NPL", numeric: "524" } );
			 countries.push( { country: "Netherlands (the)", alpha2: "NL", alpha3: "NLD", numeric: "528" } );
			 countries.push( { country: "New Caledonia", alpha2: "NC", alpha3: "NCL", numeric: "540" } );
			 countries.push( { country: "New Zealand", alpha2: "NZ", alpha3: "NZL", numeric: "554" } );
			 countries.push( { country: "Nicaragua", alpha2: "NI", alpha3: "NIC", numeric: "558" } );
			 countries.push( { country: "Niger (the)", alpha2: "NE", alpha3: "NER", numeric: "562" } );
			 countries.push( { country: "Nigeria", alpha2: "NG", alpha3: "NGA", numeric: "566" } );
			 countries.push( { country: "Niue", alpha2: "NU", alpha3: "NIU", numeric: "570" } );
			 countries.push( { country: "Norfolk Island", alpha2: "NF", alpha3: "NFK", numeric: "574" } );
			 countries.push( { country: "Northern Mariana Islands (the)", alpha2: "MP", alpha3: "MNP", numeric: "580" } );
			 countries.push( { country: "Norway", alpha2: "NO", alpha3: "NOR", numeric: "578" } );
			 countries.push( { country: "Oman", alpha2: "OM", alpha3: "OMN", numeric: "512" } );
			 countries.push( { country: "Pakistan", alpha2: "PK", alpha3: "PAK", numeric: "586" } );
			 countries.push( { country: "Palau", alpha2: "PW", alpha3: "PLW", numeric: "585" } );
			 countries.push( { country: "Palestine, State of", alpha2: "PS", alpha3: "PSE", numeric: "275" } );
			 countries.push( { country: "Panama", alpha2: "PA", alpha3: "PAN", numeric: "591" } );
			 countries.push( { country: "Papua New Guinea", alpha2: "PG", alpha3: "PNG", numeric: "598" } );
			 countries.push( { country: "Paraguay", alpha2: "PY", alpha3: "PRY", numeric: "600" } );
			 countries.push( { country: "Peru", alpha2: "PE", alpha3: "PER", numeric: "604" } );
			 countries.push( { country: "Philippines (the)", alpha2: "PH", alpha3: "PHL", numeric: "608" } );
			 countries.push( { country: "Pitcairn", alpha2: "PN", alpha3: "PCN", numeric: "612" } );
			 countries.push( { country: "Poland", alpha2: "PL", alpha3: "POL", numeric: "616" } );
			 countries.push( { country: "Portugal", alpha2: "PT", alpha3: "PRT", numeric: "620" } );
			 countries.push( { country: "Puerto Rico", alpha2: "PR", alpha3: "PRI", numeric: "630" } );
			 countries.push( { country: "Qatar", alpha2: "QA", alpha3: "QAT", numeric: "634" } );
			 countries.push( { country: "Republic of North Macedonia", alpha2: "MK", alpha3: "MKD", numeric: "807" } );
			 countries.push( { country: "Romania", alpha2: "RO", alpha3: "ROU", numeric: "642" } );
			 countries.push( { country: "Russian Federation (the)", alpha2: "RU", alpha3: "RUS", numeric: "643" } );
			 countries.push( { country: "Rwanda", alpha2: "RW", alpha3: "RWA", numeric: "646" } );
			 countries.push( { country: "Réunion", alpha2: "RE", alpha3: "REU", numeric: "638" } );
			 countries.push( { country: "Saint Barthélemy", alpha2: "BL", alpha3: "BLM", numeric: "652" } );
			 countries.push( { country: "Saint Helena, Ascension and Tristan da Cunha", alpha2: "SH", alpha3: "SHN", numeric: "654" } );
			 countries.push( { country: "Saint Kitts and Nevis", alpha2: "KN", alpha3: "KNA", numeric: "659" } );
			 countries.push( { country: "Saint Lucia", alpha2: "LC", alpha3: "LCA", numeric: "662" } );
			 countries.push( { country: "Saint Martin (French part)", alpha2: "MF", alpha3: "MAF", numeric: "663" } );
			 countries.push( { country: "Saint Pierre and Miquelon", alpha2: "PM", alpha3: "SPM", numeric: "666" } );
			 countries.push( { country: "Saint Vincent and the Grenadines", alpha2: "VC", alpha3: "VCT", numeric: "670" } );
			 countries.push( { country: "Samoa", alpha2: "WS", alpha3: "WSM", numeric: "882" } );
			 countries.push( { country: "San Marino", alpha2: "SM", alpha3: "SMR", numeric: "674" } );
			 countries.push( { country: "Sao Tome and Principe", alpha2: "ST", alpha3: "STP", numeric: "678" } );
			 countries.push( { country: "Saudi Arabia", alpha2: "SA", alpha3: "SAU", numeric: "682" } );
			 countries.push( { country: "Senegal", alpha2: "SN", alpha3: "SEN", numeric: "686" } );
			 countries.push( { country: "Serbia", alpha2: "RS", alpha3: "SRB", numeric: "688" } );
			 countries.push( { country: "Seychelles", alpha2: "SC", alpha3: "SYC", numeric: "690" } );
			 countries.push( { country: "Sierra Leone", alpha2: "SL", alpha3: "SLE", numeric: "694" } );
			 countries.push( { country: "Singapore", alpha2: "SG", alpha3: "SGP", numeric: "702" } );
			 countries.push( { country: "Sint Maarten (Dutch part)", alpha2: "SX", alpha3: "SXM", numeric: "534" } );
			 countries.push( { country: "Slovakia", alpha2: "SK", alpha3: "SVK", numeric: "703" } );
			 countries.push( { country: "Slovenia", alpha2: "SI", alpha3: "SVN", numeric: "705" } );
			 countries.push( { country: "Solomon Islands", alpha2: "SB", alpha3: "SLB", numeric: "90" } );
			 countries.push( { country: "Somalia", alpha2: "SO", alpha3: "SOM", numeric: "706" } );
			 countries.push( { country: "South Africa", alpha2: "ZA", alpha3: "ZAF", numeric: "710" } );
			 countries.push( { country: "South Georgia and the South Sandwich Islands", alpha2: "GS", alpha3: "SGS", numeric: "239" } );
			 countries.push( { country: "South Sudan", alpha2: "SS", alpha3: "SSD", numeric: "728" } );
			 countries.push( { country: "Spain", alpha2: "ES", alpha3: "ESP", numeric: "724" } );
			 countries.push( { country: "Sri Lanka", alpha2: "LK", alpha3: "LKA", numeric: "144" } );
			 countries.push( { country: "Sudan (the)", alpha2: "SD", alpha3: "SDN", numeric: "729" } );
			 countries.push( { country: "Suriname", alpha2: "SR", alpha3: "SUR", numeric: "740" } );
			 countries.push( { country: "Svalbard and Jan Mayen", alpha2: "SJ", alpha3: "SJM", numeric: "744" } );
			 countries.push( { country: "Sweden", alpha2: "SE", alpha3: "SWE", numeric: "752" } );
			 countries.push( { country: "Switzerland", alpha2: "CH", alpha3: "CHE", numeric: "756" } );
			 countries.push( { country: "Syrian Arab Republic", alpha2: "SY", alpha3: "SYR", numeric: "760" } );
			 countries.push( { country: "Taiwan (Province of China)", alpha2: "TW", alpha3: "TWN", numeric: "158" } );
			 countries.push( { country: "Tajikistan", alpha2: "TJ", alpha3: "TJK", numeric: "762" } );
			 countries.push( { country: "Tanzania, United Republic of", alpha2: "TZ", alpha3: "TZA", numeric: "834" } );
			 countries.push( { country: "Thailand", alpha2: "TH", alpha3: "THA", numeric: "764" } );
			 countries.push( { country: "Timor-Leste", alpha2: "TL", alpha3: "TLS", numeric: "626" } );
			 countries.push( { country: "Togo", alpha2: "TG", alpha3: "TGO", numeric: "768" } );
			 countries.push( { country: "Tokelau", alpha2: "TK", alpha3: "TKL", numeric: "772" } );
			 countries.push( { country: "Tonga", alpha2: "TO", alpha3: "TON", numeric: "776" } );
			 countries.push( { country: "Trinidad and Tobago", alpha2: "TT", alpha3: "TTO", numeric: "780" } );
			 countries.push( { country: "Tunisia", alpha2: "TN", alpha3: "TUN", numeric: "788" } );
			 countries.push( { country: "Turkey", alpha2: "TR", alpha3: "TUR", numeric: "792" } );
			 countries.push( { country: "Turkmenistan", alpha2: "TM", alpha3: "TKM", numeric: "795" } );
			 countries.push( { country: "Turks and Caicos Islands (the)", alpha2: "TC", alpha3: "TCA", numeric: "796" } );
			 countries.push( { country: "Tuvalu", alpha2: "TV", alpha3: "TUV", numeric: "798" } );
			 countries.push( { country: "Uganda", alpha2: "UG", alpha3: "UGA", numeric: "800" } );
			 countries.push( { country: "Ukraine", alpha2: "UA", alpha3: "UKR", numeric: "804" } );
			 countries.push( { country: "United Arab Emirates (the)", alpha2: "AE", alpha3: "ARE", numeric: "784" } );
			 countries.push( { country: "United Kingdom of Great Britain and Northern Ireland (the)", alpha2: "GB", alpha3: "GBR", numeric: "826" } );
			 countries.push( { country: "United States Minor Outlying Islands (the)", alpha2: "UM", alpha3: "UMI", numeric: "581" } );
			 countries.push( { country: "United States of America (the)", alpha2: "US", alpha3: "USA", numeric: "840" } );
			 countries.push( { country: "Uruguay", alpha2: "UY", alpha3: "URY", numeric: "858" } );
			 countries.push( { country: "Uzbekistan", alpha2: "UZ", alpha3: "UZB", numeric: "860" } );
			 countries.push( { country: "Vanuatu", alpha2: "VU", alpha3: "VUT", numeric: "548" } );
			 countries.push( { country: "Venezuela (Bolivarian Republic of)", alpha2: "VE", alpha3: "VEN", numeric: "862" } );
			 countries.push( { country: "Viet Nam", alpha2: "VN", alpha3: "VNM", numeric: "704" } );
			 countries.push( { country: "Virgin Islands (British)", alpha2: "VG", alpha3: "VGB", numeric: "92" } );
			 countries.push( { country: "Virgin Islands (U.S.)", alpha2: "VI", alpha3: "VIR", numeric: "850" } );
			 countries.push( { country: "Wallis and Futuna", alpha2: "WF", alpha3: "WLF", numeric: "876" } );
			 countries.push( { country: "Western Sahara", alpha2: "EH", alpha3: "ESH", numeric: "732" } );
			 countries.push( { country: "Yemen", alpha2: "YE", alpha3: "YEM", numeric: "887" } );
			 countries.push( { country: "Zambia", alpha2: "ZM", alpha3: "ZMB", numeric: "894" } );
			 countries.push( { country: "Zimbabwe", alpha2: "ZW", alpha3: "ZWE", numeric: "716" } );
			 countries.push( { country: "Åland Islands", alpha2: "AX", alpha3: "ALA", numeric: "248" } );

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- GENERAL ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */





